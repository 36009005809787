import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HeaderTabs, OtherAccounts } from "../../components";
import { CardButton, PrimaryCard } from "../../components/Card";
import { getClientAccounts } from "../../redux/actions/home/ClientInfoAction";
import RecentTransactions from "../../components/RecentTranactions";
import ViewRecentTransaction from "../../components/RecentTranactions/partials/ViewRecentTransaction";
import { setActiveAccount } from "../../redux/actions/home/ActiveAccountAction";
import AddAccountModal from "../../components/Modals/AddAccount/AddAccountModal";
import {
  CardButtonSkeleton,
  OtherAccountsSkeletons,
  RecentTransactionsSkeleton,
  PrimaryCardSkeleton,
} from "../../components/Skeleton";
import CustomTransition from "../../components/CustomTransition";
import ReccentTransactionSideBar from "../../components/Common/RecentTransactionsSideBar";
import { setPageTitle } from "../../helper/metaDataHelper";
import { getModuleStatus } from "../../redux/actions/lookups/getModuleStatus";
import { useRecentTransaction } from "../../hooks/useRecentTransaction";

const Accounts = () => {
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);
  const [ShowRecentTransaction, setShowRecentTransaction] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  // const { isLoading } = useSelector((state) => state.common);
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const [recentTransactionsLoading, setRecentTransactionsLoading] =
    useState(false);
  const { lastThreeTransactions } = useRecentTransaction(
    setRecentTransactionsLoading,
    "account"
  );

  useEffect(() => {
    setPageTitle("Accounts");
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        if (!user) {
          navigate("/signin");
        } else {
          setIsLoading(true);

          // Run all the async actions concurrently
          const [clientAccounts] = await Promise.all([
            dispatch(getClientAccounts()),
            dispatch(getModuleStatus({ module: "Crypto" })),
            dispatch(getModuleStatus({ module: "Bitpace" })),
            dispatch(getModuleStatus({ module: "VistaMoney" })),
          ]);

          if (clientAccounts && clientAccounts.accounts) {
            const faitAccounts = clientAccounts.accounts.filter(
              (account) => account.is_crypto === 0 || account.is_crypto === null
            );
            setClientAccounts(faitAccounts);

            if (faitAccounts.length) {
              await dispatch(setActiveAccount(faitAccounts[0]));
            }
          } else {
            console.error("Accounts data is missing or null.");
          }
        }
      } catch (error) {
        console.error("Error in getUserInfo:", error);
      }
      setIsLoading(false);
    };

    getUserInfo();
  }, [user, dispatch, navigate]);

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 min-h-screen">
        <div className="flex flex-col sm:flex-row justify-center gap-3">
          {/* First sections */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <HeaderTabs />
            {isLoading || !activeAccount ? (
              <>
                <PrimaryCardSkeleton count={4} />
                <RecentTransactionsSkeleton />
                <CardButtonSkeleton />
              </>
            ) : (
              <>
                {activeAccount && (
                  <div className="space-y-4">
                    <PrimaryCard
                      clientAccounts={clientAccounts}
                      activeAccount={activeAccount}
                      type="account"
                    />
                    {!recentTransactionsLoading ? (
                      <RecentTransactions
                        activeTransaction={activeTransaction}
                        transactions={lastThreeTransactions}
                        setActiveTransaction={setActiveTransaction}
                        setShowRecentTransaction={setShowRecentTransaction}
                      />
                    ) : (
                      <RecentTransactionsSkeleton />
                    )}
                    {user && !user.is_basic_client && (
                      <div className="md:hidden block">
                        <AddAccountModal>
                          <CardButton
                            title={"Add new account"}
                            subtitle={"Explore other currency in the world"}
                          />
                        </AddAccountModal>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {/* Second sections */}
          <div className="xl:w-[404px] w-[350px] hidden md:block mt-2">
            {isLoading ? (
              <>
                <OtherAccountsSkeletons />
                <CardButtonSkeleton />
              </>
            ) : (
              <>
                <CustomTransition show={ShowRecentTransaction}>
                  {ShowRecentTransaction && (
                    <>
                      <div className="bg-primarycard-gradient rounded-3xl p-6 mt-2 space-y-4 h-[700px]">
                        <ViewRecentTransaction
                          transaction={activeTransaction}
                          setShowRecentTransaction={setShowRecentTransaction}
                          ShowRecentTransaction={ShowRecentTransaction}
                          setActiveTransaction={setActiveTransaction}
                        />
                      </div>
                    </>
                  )}
                </CustomTransition>
                <ReccentTransactionSideBar
                  ShowRecentTransaction={ShowRecentTransaction}
                  setShowRecentTransaction={setShowRecentTransaction}
                  activeTransaction={activeTransaction}
                  setActiveTransaction={setActiveTransaction}
                />

                <CustomTransition show={!ShowRecentTransaction}>
                  {!ShowRecentTransaction && (
                    <>
                      <OtherAccounts
                        clientAccounts={clientAccounts}
                        activeAccount={activeAccount}
                      />
                      {user && !user.is_basic_client && (
                        <AddAccountModal>
                          <CardButton
                            title={"Add new account"}
                            subtitle={"Explore other currency in the world"}
                          />
                        </AddAccountModal>
                      )}
                    </>
                  )}
                </CustomTransition>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Accounts;
